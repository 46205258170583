import Link from 'next/link';
import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import styles from '../styles/ContactBlock.module.css';
import IconAvatar from './icons/IconAvatar';
import IconEmailAlt from './icons/IconEmailAlt';
import IconEnvelope from './icons/IconEnvelope';
import IconMobile from './icons/IconMobile';
import IconPhone from './icons/IconPhone';
import Container from './ui/Container';

type Inputs = {
  yourName: string;
  email: string;
  phone?: string;
  mobile?: string;
  message: string;
  address?: string;
  postcode?: string;
};

export default function ContactBlock() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Inputs>({ mode: 'onChange' });

  const [validSubmission, setValidSubmission] = useState<boolean>(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    // Send data to server
    const response = await fetch('/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    // Handle response
    if (response.status === 400) {
      const result = await response.json();
      setError(result.field, { type: 'custom', message: result.data });
    }

    // Handle response
    if (response.status === 200) {
      setValidSubmission(true);
    }
  };

  return (
    <Container size="xl">
      <div className={styles.wrap} id="contact">
        <h3>
          <Link href="https://www.officespaceintown.com/serviced-offices/">
            Serviced offices
          </Link>
          , <Link href="/meeting-rooms/">meeting spaces</Link>,{' '}
          <Link href="https://www.officespaceintown.com/virtual-offices/">
            virtual office services
          </Link>
          , and{' '}
          <Link href="https://www.officespaceintown.com/venues-for-events/">
            event venues
          </Link>{' '}
          available to hire.
          <br />
          On the hunt for any of the above?
          <br />
          Get in touch for further details or to book a viewing.
        </h3>

        <div className={styles.content}>
          <div className={styles.form}>
            <h4>Send us a message...</h4>

            {!validSubmission ? (
              <form action="/api/contact" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className={styles.form_row}>
                    <div className={styles.form_item}>
                      <label htmlFor="yourName">
                        <span>Your Name</span>
                        <div className={styles.input_wrap}>
                          <IconAvatar width={12} height={12} />
                          <input
                            type="text"
                            className={errors.yourName && styles.error}
                            {...register('yourName', {
                              required: true,
                              maxLength: 120,
                            })}
                          />
                        </div>
                      </label>
                      {errors.yourName?.type === 'required'
                        && errors.yourName?.message === '' && (
                          <span className={styles.input_error}>
                            Your name is required
                          </span>
                      )}
                      {errors.yourName?.message && (
                        <span className={styles.input_error}>
                          {errors.yourName.message}
                        </span>
                      )}
                    </div>

                    <div className={styles.form_item}>
                      <label htmlFor="email">
                        <span>Email</span>
                        <div className={styles.input_wrap}>
                          <IconEmailAlt width={12} height={12} />
                          <input
                            type="email"
                            className={errors.email && styles.error}
                            {...register('email', {
                              required: true,
                              maxLength: 120,
                            })}
                          />
                        </div>
                      </label>
                      {errors.email?.type === 'required'
                        && errors.email?.message === '' && (
                          <span className={styles.input_error}>
                            Your email is required
                          </span>
                      )}
                      {errors.email?.message && (
                        <span className={styles.input_error}>
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className={styles.form_row}>
                    <div className={styles.form_item}>
                      <label htmlFor="phone">
                        <span>Telephone</span>
                        <div className={styles.input_wrap}>
                          <IconPhone width={12} height={12} />
                          <input
                            type="text"
                            className={errors.phone && styles.error}
                            {...register('phone', {
                              maxLength: 120,
                            })}
                          />
                        </div>
                      </label>
                      {errors.phone?.message && (
                        <span className={styles.input_error}>
                          {errors.phone.message}
                        </span>
                      )}
                    </div>

                    <div className={styles.form_item}>
                      <label htmlFor="mobile">
                        <span>Mobile</span>
                        <div className={styles.input_wrap}>
                          <IconMobile width={12} height={12} />
                          <input
                            type="text"
                            className={errors.mobile && styles.error}
                            {...register('mobile', {
                              maxLength: 120,
                            })}
                          />
                        </div>
                      </label>
                      {errors.mobile?.message && (
                        <span className={styles.input_error}>
                          {errors.mobile.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className={styles.form_item}>
                    <label htmlFor="message">
                      <span>Message</span>
                      <div className={styles.input_wrap}>
                        <IconEnvelope width={12} height={12} />
                        <textarea
                          className={errors.message && styles.error}
                          {...register('message', { required: true })}
                        />
                      </div>
                    </label>
                    {errors.message?.type === 'required'
                      && errors.message?.message === '' && (
                        <span className={styles.input_error}>
                          Please enter a message
                        </span>
                    )}
                    {errors.message?.message && (
                      <span className={styles.input_error}>
                        {errors.message.message}
                      </span>
                    )}
                  </div>

                  <label htmlFor="address" className={styles.address}>
                    Address
                    <input
                      type="text"
                      tabIndex={-1}
                      autoComplete="off"
                      {...register('address')}
                    />
                  </label>
                  {errors.address?.message && (
                    <span className={styles.input_error}>
                      {errors.address.message}
                    </span>
                  )}

                  <label htmlFor="postcode" className={styles.postcode}>
                    Postcode
                    <input
                      type="text"
                      tabIndex={-1}
                      autoComplete="off"
                      {...register('postcode')}
                    />
                  </label>
                  {errors.postcode && errors.postcode?.message === '' && (
                    <span className={styles.input_error}>
                      There was an error submitting the form
                    </span>
                  )}
                </div>

                <button type="submit">Send</button>
              </form>
            ) : (
              <div className={styles.success}>
                Your message has been sent successfully
              </div>
            )}
          </div>

          <div className={styles.details}>
            <div>
              <h3>Call us</h3>
              <a href="tel:0844 4121796" target="_blank" rel="noreferrer">
                0844 4121796
              </a>
            </div>

            <div>
              <h3>Email</h3>
              <a
                href="mailto:sales@officespaceintown.com"
                target="_blank"
                rel="noreferrer"
              >
                sales@officespaceintown.com
              </a>
            </div>

            <div>
              <h3>Head Office</h3>
              <address>20 St Dunstan&apos;s Hill, London EC3R 8HL</address>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
